@import 'assets/styles/variables';
@import 'assets/styles/mixin';

.patient-view-tab {
    width: 100%;
    @include flexColumnNoWrap(20px, center, center);
    position: relative;
    height: 100vh;
    
    &__top{
        width: 100%; 
        border-radius: 8px;
        background: $main-light;
        box-shadow: 0px 0px 1px 0px rgba(192, 191, 191, 0.00), 0px 0px 1px 0px rgba(192, 191, 191, 0.01), 0px 0px 1px 0px rgba(192, 191, 191, 0.05), 0px 0px 1px 0px rgba(192, 191, 191, 0.09);
        padding: 24px;
        @include flexColumnNoWrap(7px, flex-start, center);
        position: absolute;
        top: 0;
        z-index: 1;
    
        &__header{
            width: 100%;
            @include flexRowNoWrap(0, center, space-between);
            @include fontSemibold(20px, normal);
            color: $black;    
        }
    
        &__content{
            width: 100%;
            max-height: 93px;
            overflow-y: hidden;
            @include scroll;
            @include flexRowNoWrap(54px, flex-start, flex-start);

            &.fullcard{
                overflow-y: scroll;
                max-height: 70vh;
            }
    
            &__block{
                width: 460px;
                @include flexColumnNoWrap(16px, flex-start, center);

                &.expanded{
                    & .card-field__value{
                        min-width: 24.3vw;
                    }
                }
                &__label{
                    @include fontRegular(14px, normal);
                    color: $black;
                }

                &__btn{
                    margin-top: -8px;
                    @include fontRegular(14px, normal);
                    color: $primary;
                    cursor: pointer;
                    transition: color 0.2s;
    
                    &:hover{
                        opacity: 0.6;
                    }
                }
            }
        }

        &__dropdown{
            position: absolute;
            bottom: 24px;
            right: 42px;
            cursor: pointer;
            transition: color 0.2s;

            &.reversed{
                transform: rotate(180deg);
            }
    
            &:hover{
                opacity: 0.6;
            }
        }
    }

    &__bottom{
        width: 100%;
        position: absolute;
        bottom: 0px;

        background: #fff;
        border-radius: 8px;
        background:  #FFF;
        box-shadow: 0px 0px 1px 0px rgba(192, 191, 191, 0.00), 0px 0px 1px 0px rgba(192, 191, 191, 0.01), 0px 0px 1px 0px rgba(192, 191, 191, 0.05), 0px 0px 1px 0px rgba(192, 191, 191, 0.09);
    }
    
}
