@import 'assets/styles/variables';
@import 'assets/styles/mixin';

.login-form {
    @include flexColumnNoWrap(6px, center, center);

    &__btn{
        margin-top: 10px;
    }

    &__footer{
        margin-top: 6px;
        display: flex;
        gap: 4px;
        @include fontRegular(14px, normal);
        color: $muted-slate;

        &__link{
            cursor: pointer;
            color: $primary;
            text-decoration: none;
            transition: color 0.2s;

            &:hover{
                opacity: 0.6;
            }
        }

    }
}
