@import '../../assets/styles/variables';
@import '../../assets/styles/mixin';

.labeled-input {
    display: flex;
    flex-direction: column;
    gap: 12px;
    position: relative;

    &__label-block{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__extra-label{
        @include fontRegular(14px, normal);
        color: $primary;
        cursor: pointer
    }

    &__label {
        @include fontRegular(14px, normal);
    }
    
    input {
        padding: 12px 48px 12px 16px;
        width: 408px;
        @include fontRegular(15px, normal);
        color: $black;
        border-radius: 6px;
        border: 1px solid $grey;
        transition: border-color 0.2s;
    
        &:focus {
            border: 3px solid $focus-blue;

            & + .labeled-input__extra-icon {
                 top: 56px;
            }
        }
    }

    &__extra-icon {
        width: 16px;
        height: 16px;
        position: absolute;
        right: 16px;
        top: 53px;
        transform: translateY(-50%);
    }
    
    .error {
        color: $danger;
        font-size: 0.8rem;
        margin-top: 0.5rem;

       
    }

    &__error-cont{
        display: flex;
        align-items: center;
        gap: 4px;

    }
    &__error-text{
        @include fontRegular(13px, normal);
        color: #FE3A3F;
    }

}
