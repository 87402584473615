@import 'assets/styles/variables';
@import 'assets/styles/mixin';

.warning-block {
    position: absolute;
    z-index: 100;
    width: 397px;
    border-radius: 8px;
    border: 1px solid #FE3A3F;
    background: #FFE4E5;
    @include flexColumnNoWrap(16px, flex-start, flex-start);
    padding: 10px 16px;
    gap: 10px;

    &__header{
        color: $black;
        @include fontMedium(12px, 18px);
    }

    &__text{
        color: $black;
        @include fontRegular(12px, 18px);
    }

}
