@import 'assets/styles/variables';
@import 'assets/styles/mixin';

.custom-button {
  @include flexRowNoWrap(var(--gap));
  font: inherit;
  text-align: center;
  padding: var(--padding);
  border-radius: 8px;
  height: fit-content;
  background-color: inherit;
  border: inherit;
  color: inherit;
  transition: $transition;

  .MuiSvgIcon-root {
    width: var(--icon-width);
    height: var(--icon-height);
    color: inherit;
  }

  svg {
    width: var(--icon-width);
    height: var(--icon-height);

    path,
    circle {
      stroke: currentColor;
    }
  }

  &__text {
    color: inherit;
  }

  &.sidebar__btn-create{
    padding: 11px 15px;
    justify-content: space-between;
    @include fontRegular(14px, normal);
  }
}
