@import 'assets/styles/variables';
@import 'assets/styles/mixin';

.faq-list-item {
    width: 100%;
    padding: 16px 16px 0 16px;
    border-top: 0.6px solid   #AEAEAE; 

    &__question {
        display: flex;
        justify-content: space-between;
        cursor: pointer;
        @include fontSemibold(15px, normal);
        color: $black;
      }

      &__icon {
        margin-left: 10px;
      }

      &__answer {
        
        @include fontRegular(14px, normal);
        color: #AEAEAE;

        &.opened{
            margin-top: 8px;
        }
      }
}
  
  