@import 'assets/styles/variables';
@import 'assets/styles/mixin';

.main-page-content {
    @include flexColumnNoWrap(20px, center, flex-start);
    background: $bg;
    width: calc(100vw - 263px);
    height: 100vh;
    padding: 32px 24px 16px 24px;
    
}
