@import 'assets/styles/variables';
@import 'assets/styles/mixin';


@keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
}

.chat-input {
    padding: 0 24px;
    width: 100%; 
    @include flexColumnNoWrap(7px, center, center);
    height: auto;

    &__input-block{
        @include flexRowNoWrap(0, center, space-between);
        padding: 12px 24px;
        width: 100%;
        height: 44px;
        border-radius: 20px;
        background:  #EBEBEC;
        height: auto;
        &__loader {
            margin-right: 10px;
            width: 20px;
            height: 20px; 
            animation: rotate 1s linear infinite;
          }
          
          

        & textarea{
            width: 100%;
            border: none;
            outline: none;
            background: none;
            @include fontRegular(14px, 20px);
            color: $black;
            resize: none;
            // transition: all 0.3s ease;
            height: auto;
            @include scroll;
            // &:focus {
            //     height: 132px;
            //     &::parent {
            //       height: auto; // Ensure parent adjusts to textarea height
            //     }
            //   }
        }

        &__send-icon{
            position: relative;
            width: 34px;
            height: 34px;
            cursor: pointer;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;

            &:hover{
                background: #F6F6F6;
            }
        }
    }

    &__footer{
        @include fontRegular(11px, normal);
        text-align: center;
        color: $muted-slate;
        margin-bottom: 16px;
    }
}
