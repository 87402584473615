@import 'assets/styles/variables';
@import 'assets/styles/mixin';

.sidebar-error-block {
    border-radius: 8px;
    border: 1px solid $error;
    background: $error-bg;
    padding: 10px 16px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 215px;
    align-self: center;

    &__title, &__details{
        @include fontRegular(12px, 18px);
        color: $black;
    }

}
