@import 'assets/styles/variables';
@import 'assets/styles/mixin';

.card-form-dropdown {
  @include flexColumnNoWrap(4px, flex-start, center);
  width: 448px;

  &__label {
    @include fontRegular(14px, normal);
    color: $black;

    & span{
      color: #FE3A3F;
  }
  }

  &__custom-select {
    position: relative;
    width: 100%;
    border: 1px solid $muted-light;
    border-radius: 6px;
    padding: 10px 16px 10px 16px;
    margin-top: 2px;
    height: 34px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: transparent;
    @include fontRegular(13px, normal);
    color: $muted-dark;
    transition: border 0.2s;

    &:focus {
      background: #F9F9FF;
      border: none;
    }
  }

  &__selected-value {
    flex: 1;
  }

  &__options {
    position: absolute;
    top: calc(100% + 4px);
    left: 0;
    width: 100%;
    border: 1px solid $muted-light;
    border-radius: 6px;
    background: #fff;
    z-index: 10;
  }

  &__option {
    padding: 10px 16px;
    cursor: pointer;

    &:hover {
      background: #f0f0f0;
    }
  }

  &__dropdown svg {
    position: absolute;
    right: 15px;
    pointer-events: none;
  }
}
