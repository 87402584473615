@import 'assets/styles/variables';
@import 'assets/styles/mixin';

.two-factor-code-form {

    @include flexColumnNoWrap(12px, center, center);

    &__title{
        margin-top: -24px;
        margin-bottom: 22px;
        @include fontRegular(14px, normal);
        color: $muted-dark; 
        text-align: center;
    }
    &__header{
        margin-top: -16px;
        @include fontRegular(14px, normal);
        color: $muted-dark;
        font-weight: 400;
        text-align: center;
    }
    
    &__btn{
        margin-top: 10px;
    }

    &__button{
        margin-top: 16px;
        margin-bottom: -6px;
        @include fontRegular(14px, normal);
        color: $primary;

    }

    &__error{ 
        @include fontRegular(14px, normal);
        color: #FE3A3F;
    }

    &__footer{
        margin-top: 6px;
        display: flex;
        gap: 4px;
        @include fontRegular(14px, normal);
        color: $muted-slate;

        &__link{
            cursor: pointer;
            color: $primary;
            text-decoration: none;
            transition: color 0.2s;

            &:hover{
                opacity: 0.6;
            }
        }

    }
}
