@import 'assets/styles/variables';
@import 'assets/styles/mixin';

.card-form-textarea {
    @include flexColumnNoWrap(4px, flex-start, center);
    width: 448px;

    &__label{
        @include fontRegular(14px, normal);
        color: $black;

        & span{
            color: #FE3A3F;
        }
    }

    &__input{
        @include scroll;
        resize: none;
        padding: 10px 16px;
        width: 100%; 
        border: 1px solid $muted-light;
        border-radius: 6px;
        background: transparent;
        padding: 10px 12px;
        margin-top: 8px; 
        @include fontRegular(13px, normal);
        color: $muted-dark;
        outline: none; 

        &:focus{
            border: 1px solid #F4F4FF;
            background: #F9F9FF;
            border: none;
        }
    }
}
