@import 'assets/styles/variables';
@import 'assets/styles/mixin';

.card-form-input {
    @include flexColumnNoWrap(4px, flex-start, center);
    width: 448px;

    &__label{
        @include fontRegular(14px, normal);
        color: $black;
        display: flex; 
        gap: 16px;

        & span{
            color: #FE3A3F;
        }
    }

    &__selector{
        display: flex;
        align-items: center;
        gap: 8px;

        &__selector-btn{
            @include fontRegular(8px, normal);
            border-radius: 10px;
            border: 0.8px solid rgba(169, 184, 199, 0.40);
            color: $muted-light; 
            padding: 3px 6px;
            cursor: pointer;


            &.active{ 
                background: #FFF;
                border: 0.8px solid $primary;
                color: $primary;
            }
        }
    }

    &__input{
        padding: 10px 16px;
        width: 100%;
        height: 36px;
        border: 1px solid $muted-light;
        border-radius: 6px;
        margin-top: 2px; 
        @include fontRegular(13px, normal);
        color: $muted-dark;
        outline: none;
        transition: border 0.2s;

        &:focus{
            background: #F9F9FF;
            border: none;
        }
    }
}
