@import 'assets/styles/variables';
@import 'assets/styles/mixin';

.icon-button {
  @include flexRowNoWrap;
  padding: var(--padding-icon);
  border-radius: var(--padding-icon);
  color: inherit;
  border: inherit;
  background-color: inherit;
  height: fit-content;
  scale: 1;
  transition: $transition;

  &:active {
    scale: 0.95;
  }

  .MuiSvgIcon-root {
    width: var(--icon-width);
    height: var(--icon-height);
    color: inherit;
  }

  > svg {
    width: var(--icon-width);
    height: var(--icon-height);

    path,
    circle {
      stroke: currentColor;
    }
  }
}
