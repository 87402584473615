@import 'assets/styles/variables';
@import 'assets/styles/mixin';

.patient-edit-tab {
    width: 100%; 
    border-radius: 8px;
    background: $main-light;
    box-shadow: 0px 0px 1px 0px rgba(192, 191, 191, 0.00), 0px 0px 1px 0px rgba(192, 191, 191, 0.01), 0px 0px 1px 0px rgba(192, 191, 191, 0.05), 0px 0px 1px 0px rgba(192, 191, 191, 0.09);
    padding: 24px;
    @include flexColumnWrap(16px, flex-start, center);
    flex-flow: wrap;
    overflow-y: scroll;
    @include scroll;

    &__header{ 
        width: 100%;
        @include flexRowNoWrap(0, center, space-between);
        @include fontSemibold(20px, normal);
        color: $black;    
        position: relative;

        &__close{
            position: absolute;
            right: -20px;
            top: -15px;
            cursor: pointer;
        }
    }

    &__content{

        width: 100%;
        @include flexRowNoWrap(24px, flex-start, flex-start);

        
        &__block{
            @include flexColumnNoWrap(16px, flex-start, center);

            &__section {
                width: 480px;
                @include flexColumnNoWrap(8px, flex-start, center); 
                padding: 20px 16px;
                border-radius: 8px;
                background: #F4F4FF;
            }
        }

    }

    &__button-create{
        align-self: flex-end;
        margin-left: auto;
    }
}
