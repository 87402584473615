@import 'assets/styles/variables';
@import 'assets/styles/mixin';

.chat-list {
    padding: 16px 24px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
    overflow-y: scroll;
    max-height: 65vh;
    @include scroll;

    .chat-message{
        max-width: 624px;
        padding: 16px 24px;
        @include fontRegular(14px, 20px);
        color: $black;

        &.user{
            align-self: flex-end;
            border-radius: 20px 20px 0px 20px;
            background: #EBEBEC;
        }

        &.assistant{
            align-self: start;
            border-radius: 20px 20px 20px 0px;
            background: #E4F6FF;
        }

        &.user, &.assistant{
            h2 {
                @include fontSemibold(15px, normal);
               
                margin: 16px 0;
              }
            h3 {
                @include fontSemibold(15px, normal);
               
                margin: 16px 0;
              }
              h4{
                @include fontSemibold(14px, normal);
                margin: 12px 0;
              }
              
              hr{
                opacity: 0;
              }
              strong{
                @include fontSemibold(15px, normal);
              };

              ol[start] {
                @include fontSemibold(15px, normal);
                list-style: none;
                margin: 16px 0;
              }


           
        }



    }
}

