@import 'assets/styles/variables';
@import 'assets/styles/mixin';

.loader {
    margin-top: 100px;
    @include flexColumnNoWrap(28px, center, center);
    width: 185px;
    height: 200px; 
  
     

    &__spinner {
        width: 180px;
        height: 123px;
        background: url('../../assets/animation/loader.gif') no-repeat center;
    }

    &__text{
        color: $black;
        font-size: 20px;
        @include fontSemibold(20px, normal);
    }
}
