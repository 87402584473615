@import 'assets/styles/variables';
@import 'assets/styles/mixin';

.sidebar {
    background: #fff; 
    @include flexColumnNoWrap(34px, center, flex-start);
    width: 263px;
    height: 100vh;
    padding: 32px 24px;

    &__settings {
        cursor: pointer;
    }

    &__header{
        display: flex;
        width: 100%;
    }

    &__credits{
        @include flexRowNoWrap(6px, center, center);
        align-self: flex-end;
        gap: 12px;
        margin-bottom: -18px;

        &__text{
            @include fontRegular(14px, 18px);
            color: $muted-dark;
        }

        &__value{
            @include fontMedium(14px, 18px);
            color: $black;

            &.low-credits{
                color: $warning-yellow;
            }

            &.no-credits{
                color: $error;
            }
        }


    }

    &__list__cont {
        margin-left: 10px;
        margin-top: -18px;
        flex-grow: 1;
    }

    &__footer{
        align-self: flex-start;
        @include flexColumnNoWrap(24px, flex-start, flex-start);

        &__logout{
            @include flexRowNoWrap(6px, center, flex-start);
            cursor: pointer;
        }

        &__faq{
            @include flexRowNoWrap(6px, flex-start, flex-start);
            cursor: pointer;
        }
    }



}
