@import 'assets/styles/variables';
@import 'assets/styles/mixin';

.card-list {
    width: 268px;
    @include flexColumnNoWrap(5px, flex-start, flex-start);
    @include fontRegular(14px, normal);

    &__label{
        color: $black;
    }

    &__value{
        padding-left: 8px;
        
        color: $muted-dark;
        text-overflow: ellipsis;
    }
    
}
