@import 'assets/styles/variables';
@import 'assets/styles/mixin';

.two-factor-code-input {
    display: flex;
    justify-content: space-between;
    width: 306px;
    margin: 0 auto;
    }
    
    .two-factor-code-input__input {
    @include fontRegular(15px, normal);
    color: $black;
    width: 41px;
    height: 42px;
    padding: 12px 12px;
    text-align: center; 
    border-radius: 6px;
    border: 1px solid $muted-light;

    &--error{
        border: 1px solid #FE3A3F;
    }

    &:focus {
        border: 3px solid #89CFF0;
    }
      
}
