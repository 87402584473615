@import 'assets/styles/variables';
@import 'assets/styles/mixin';

.sidebar-list {
    @include flexColumnWrap(10px, flex-start, flex-start);
    height: 400px;
    overflow-y: scroll;
    @include scroll;   
    width: 240px; 
 
    &__item {
        cursor: pointer;
        margin-left: 10px;
        display: flex;
        width: 215px;
        padding: 12px 16px 12px 16px;
        // flex-direction: column;
        justify-content: space-between;
        align-items: center;
        gap: 4px;
        border-radius: 8px;
        background: #F8F8FF;

        &__block{
            width: 150px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
        }

        &__title{
            @include fontMedium(13px, normal);
            color: $muted-dark;
        }

        &__details{
            @include fontRegular(11px, normal);
            color: $muted-light;
        
        }

        &__delete{
            width: 20px;
            height: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            visibility: hidden;
            border-radius: 50%;
            background: #fff;
        }

        &:hover {
            background-color: $main-light;

            & .sidebar-list__item__delete{
                visibility: visible;
            }
        }

        &.blocked{
            background: #EEEEF1;


            & .sidebar-list__item__title, .sidebar-list__item__details{ 
                color: $muted-light;
            
            }
        }

        &.selected{
            background: #E6E6FA;
           
            & .sidebar-list__item__title{ 
                color: $black;
            }
    
            & .sidebar-list__item__details{ 
                color: $muted-slate;
            
            }
        }

        


    }

    
}
