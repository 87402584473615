@import 'assets/styles/variables';
@import 'assets/styles/mixin';

.faq-page {
    @include flexColumnNoWrap(44px);
    margin-bottom: 40px;
    overflow: hidden;

    &__back-arrow {
        position: fixed;
        top: 24px;
        left: 40px;
        cursor: pointer;
        z-index: 1;
    }

    &__top-cont {
        position: fixed;
        top: 0;
        width: 100%;
        height: 284px;
        background-color: #F6F6F6;  
        background-image: url('../../assets/images/doctor.png') ; 
        background-repeat: no-repeat; 
        background-position: right;  
        background-size: 684px 284px;

        &__title-block {
            margin: 102px auto 0;
            width: 928px;
            @include flexColumnNoWrap(12px, flex-start, center);

            &__title {
                @include fontMedium(40px, normal);
                color: $black;
            }

            &__sub-title {
                @include fontRegular(16px, normal);
                color: $black;

                & span {
                    color: $primary;
                }
            }
        }
    }

    &__bottom-cont {
        margin-top: 328px; 
        width: 100%;
        height: 370px;
         
        overflow-y: auto;
        @include scroll; 

        &__list{
            width: 928px;
            margin: 0 auto;
            @include flexColumnNoWrap(16px, flex-start, center);
        }
    }
}
