@import 'assets/styles/variables';
@import 'assets/styles/mixin';

.search-input {
    display: flex;
    height: 40px;
    padding: 12px 16px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 4px;
    align-self: stretch;
    border-radius: 8px;
    background: $grey-light;
    margin-bottom: -18px;

    &__input{
        @include fontMedium(13px, normal);
        color: $muted-dark;
        &.error{
            color: $error;
        }
    }


}
