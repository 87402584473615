@import 'assets/styles/variables';
@import 'assets/styles/mixin';

.required-modal {
    @include flexColumnNoWrap(16px);
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, 0);
    width: 432px;
    height: fit-content;
    padding: 28px;
    border-radius: 8px;
    background-color: $white;
    box-shadow: $shadow-modal;
    outline: none;

    &:focus {
      outline: none;
    }
  
    & *:focus {
      outline: none;
    }
  
    .icon-button {
      position: absolute;
      top: 14px;
      right: 14px;
    }
  
    &__title {
      @include fontSemibold(20px, normal);
      color: $black;
    }

    &__content{
        @include fontRegular(14px, normal);
        color: $muted-dark;
       
    }
  
    &__buttons {
      @include flexRowNoWrap(8px);
      align-self: flex-end;
    }
}
