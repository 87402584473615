@import 'assets/styles/variables';
@import 'assets/styles/mixin';

.card-field {
    width: 268px;
    // @include flexRowNoWrap(5px, flex-start, flex-start);
    @include fontRegular(14px, normal);
    display: flex;
    flex-direction: row;
    gap: 5px;

    &.col{
        flex-direction: column;
    }

    &__label{
        white-space: nowrap;
        color: $muted-dark;
       
    }

    &__value{
        white-space: wrap;
        min-width: 15vw;
        color: $black;
    }
    
}
