@import '../../assets/styles/variables';
@import '../../assets/styles/mixin';

.auth-form {
    @include flexColumnNoWrap($gap: 24px, $align-items: center, $justify-content: center);
    width: 544px;

    &__top{
        width: 100%;
        background-color: transparent;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 4px;

        &__title{
            margin-top: 4px;
            @include fontBold(24px, normal);
            color: $white;

        }
    } 

    &__container{
        @include flexColumnNoWrap($gap: 16px, $align-items: center, $justify-content: center);
        width: 100%;
        border-radius: 8px;
        background: $white;
        box-shadow: 0px 0px 1px 0px rgba(192, 191, 191, 0.00), 0px 0px 1px 0px rgba(192, 191, 191, 0.01), 0px 0px 1px 0px rgba(192, 191, 191, 0.05), 0px 0px 1px 0px rgba(192, 191, 191, 0.09);
        padding: 60px 68px;

        &__title{
            margin-bottom: 12px;
            @include fontBold(24px, normal);
            color: $black; 
        }
        &__text{
            margin-top: -12px;
            color: $muted-dark;
            @include fontRegular(15px, 20px);
            font-style: normal;
            margin-bottom: 12px;
        }
    }

}
