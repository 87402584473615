@import 'assets/styles/variables';
@import 'assets/styles/mixin';

.log-in-page {
    width: 100%;
    height: 100vh;
    background: url('../../assets/images/loginBackground.jpeg') no-repeat center center/cover;

    @include flexColumnNoWrap($gap: 20px, $align-items: center, $justify-content: center);

}
